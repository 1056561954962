import axios from 'axios';
import { toast } from 'react-toastify';
const API = (token) =>
  axios.create({
    baseURL: "https://core.bluecaller.com",
    headers: { Authorization: 'Bearer '+token },
  });
let url = "https://core.bluecaller.com";
console.log(url);
export const loginUser = async (body) => {
  try {
    return await axios.post(`${url}/api/user/login`, body);
  } catch (error) {
    console.log(error)
    console.log('error in loginuser api');
  }
};
export const googleAuth = async (body) => {
  try {
    return await axios.post(`${url}/api/user/google`, body);
  } catch (error) {
    console.log(error);
  }
};
export const registerUser = async (body) => {
  try {
    return await axios.post(`${url}/api/user/register`, body);
  } catch (error) {
    console.log(error);
    console.log('error in register api');
  }
};
export const validUser = async () => {
  try {
    const token = localStorage.getItem('userToken');
    console.log(token, 'valid user token')
    const { data } = await API(token).get(`/api/user/valid`, {
      headers: { Authorization:"Bearer " +token },
    });
    console.log(data,'valid user data');
    return data;
  } catch (error) {
    console.log(error);
    console.log('error in valid user api');
  }
};
export const searchUsers = async (id) => {
  //console.log(id);
  try {
    const token = localStorage.getItem('userToken');

    return await API(token).get(`/api/user/searchUsers?search=${id}`);
  } catch (error) {
    console.log('error in search users api');
  }
};
export const updateUser = async (id, body) => {
  try {
    const token = localStorage.getItem('userToken');

    const { data } = await API(token).patch(`/api/users/update/${id}`, body);
    return data;
  } catch (error) {
    console.log('error in update user api');
    toast.error('Something Went Wrong.try Again!');
  }
};
export const checkValid = async () => {
  const data = await validUser();
  console.log(data);
  if (!data?.user) {
    window.location.href = '/login';
  } else {
    window.location.href = '/chats';
  }
};
